import styled, { css } from 'styled-components'
import PropTypes, { InferProps } from 'prop-types'
import { DefaultLoadingSpinner } from '@/components/generic/query-result-handler'
import useIframe from './useIframe'

const Iframe: React.FC<IframeProps> = ({
  url,
  id,
  noTranslate,
  title,
  dataTestId,
  onLoad: onLoaded,
  ...props
}) => {
  const { isLoading, onLoad, ref } = useIframe(url)

  return (
    <>
      {isLoading && <DefaultLoadingSpinner />}
      <StyledIframe
        ref={ref}
        id={id}
        className={noTranslate ? 'notranslate' : ''}
        src={url}
        title={title}
        frameBorder="0"
        onLoad={() => {
          onLoad()
          onLoaded?.(ref)
        }}
        isLoading={isLoading}
        data-testid={dataTestId}
        {...props}
      />
    </>
  )
}

const StyledIframe = styled.iframe<{ isLoading: boolean }>`
  width: 1px;
  min-width: 100%;
  ${({ isLoading }) =>
    isLoading
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `}
`
StyledIframe.displayName = 'StyledIframe'

const IframePropTypes = {
  url: PropTypes.string,
  id: PropTypes.string,
  noTranslate: PropTypes.bool,
  title: PropTypes.string,
  dataTestId: PropTypes.string,
  height: PropTypes.string,
  onLoad: PropTypes.func,
}

Iframe.propTypes = IframePropTypes
type IframeProps = InferProps<typeof IframePropTypes>

export type { IframeProps }
export default Iframe
