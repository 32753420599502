import { useRouter } from 'next/router'
import WithDefaultLayout from '@/components/generic/layouts/default'
import Throw404 from '@/components/generic/throw-404'
import PreRegPage from '@/components/events/pages/pre-reg'

// Matches: /pre/[id]

const PreReg = () => {
  const {
    query: { id },
  } = useRouter()

  if (Array.isArray(id)) return <Throw404 />

  return <PreRegPage eventId={id} />
}

export default WithDefaultLayout(PreReg, {
  hideLeader: true,
})
